import typeToReducer from 'type-to-reducer'
import TYPES from './types'

const initialState = {
  data: null,
  addresses: [],
  getProfileStatus: {},
  getAddressStatus: {},
  createAddressStatus: {},
  deleteAddressStatus: {},
  editAddressStatus: {},
}

const AuthReducer = typeToReducer(
  {
    [TYPES.GET_PROFILE]: {
      PENDING: state => ({
        ...state,
        getProfileStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        data: {
          ...action.payload,
          // photoURL: filterImage(action.payload.photoURL)
        },
        getProfileStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        getProfileStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
    [TYPES.GET_ADDRESS]: {
      PENDING: state => ({
        ...state,
        getAddressStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addresses: action.payload,
        getAddressStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        getAddressStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
    [TYPES.CREATE_ADDRESS]: {
      PENDING: state => ({
        ...state,
        createAddressStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addresses: action.payload,
        createAddressStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        createAddressStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
    [TYPES.DELETE_ADDRESS]: {
      PENDING: state => ({
        ...state,
        deleteAddressStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addresses: action.payload,
        deleteAddressStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        deleteAddressStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
    [TYPES.EDIT_ADDRESS]: {
      PENDING: state => ({
        ...state,
        editAddressStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addresses: action.payload,
        editAddressStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        editAddressStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
    [TYPES.LOG_OUT]: () => ({
      ...initialState,
    }),
  },
  initialState
)

export default AuthReducer
