module.exports = [{
      plugin: require('/Users/3rath/MUZE/Pumpkin/warrix-crm-web/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/3rath/MUZE/Pumpkin/warrix-crm-web/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#EF5B34","theme_color":"#EF5B34","display":"minimal-ui","icon":"src/assets/logo.png"},
    },{
      plugin: require('/Users/3rath/MUZE/Pumpkin/warrix-crm-web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
