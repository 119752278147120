import { combineReducers } from 'redux'
import authReducer from '../modules/auth/reducer'
import historyReducer from '../modules/history/reducer'
import memberReducer from '../modules/member/reducer'
import geoReducer from '../modules/geo/reducer'
import rewardReducer from '../modules/reward/reducer'

export default combineReducers({
  auth: authReducer,
  history: historyReducer,
  member: memberReducer,
  geo: geoReducer,
  reward: rewardReducer,
})
