import typeToReducer from 'type-to-reducer'
import TYPES from './types'

const initialState = {
  data: null,
  getGeoStatus: {},
}

const GeoReducer = typeToReducer(
  {
    [TYPES.GET_GEO]: {
      PENDING: state => ({
        ...state,
        getGeoStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        data: action.payload,
        getGeoStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        getGeoStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
  },
  initialState
)

export default GeoReducer
