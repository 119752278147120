import typeToReducer from 'type-to-reducer'
import TYPES from './types'
import unionBy from 'lodash/unionBy'

const initialState = {
  transactions: [],
  redemptions: [],
  getTransactionStatus: {},
  getRedemptionStatus: {},
  shouldGetMoreTransaction: true,
  shouldGetMoreRedemption: true,
}

const HistoryReducer = typeToReducer(
  {
    [TYPES.GET_HISTORY_TRANSACTION]: {
      PENDING: state => ({
        ...state,
        getTransactionStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        transactions: action.meta
          ? unionBy(state.transactions, action.payload, 'id')
          : action.payload,
        shouldGetMoreTransaction: action.payload.length > 3,
        getTransactionStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        getTransactionStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
    [TYPES.GET_HISTORY_REDEMPTION]: {
      PENDING: state => ({
        ...state,
        getRedemptionStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        redemptions: action.meta
          ? unionBy(state.redemptions, action.payload, 'id')
          : action.payload,
        shouldGetMoreRedemption: action.payload.length > 3,
        getRedemptionStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        getRedemptionStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
    [TYPES.CLEAR_HISTORY]: () => ({
      ...initialState,
    }),
  },
  initialState
)

export default HistoryReducer
