export default {
  OBSERVE_AUTH_PENDING: 'OBSERVE_AUTH_PENDING',
  OBSERVE_AUTH_FULFILLED: 'OBSERVE_AUTH_FULFILLED',
  OBSERVE_AUTH_REJECTED: 'OBSERVE_AUTH_REJECTED',
  REGISTER_WITH_FACEBOOK_PENDING: 'REGISTER_WITH_FACEBOOK_PENDING',
  REGISTER_WITH_FACEBOOK_FULFILLED: 'REGISTER_WITH_FACEBOOK_FULFILLED',
  REGISTER_WITH_FACEBOOK_REJECTED: 'REGISTER_WITH_FACEBOOK_REJECTED',
  FETCH_MY_CARDS_SUCCESS: 'FETCH_MY_CARDS_SUCCESS',
  GET_PROFILE: '@API_GET_PROFILE',
  GET_ADDRESS: '@API_GET_ADDRESS',
  CREATE_ADDRESS: '@API_CREATE_ADDRESS',
  DELETE_ADDRESS: '@API_DELETE_ADDRESS',
  EDIT_ADDRESS: '@API_EDIT_ADDRESS',
  LOG_OUT: 'LOG_OUT',
}
